import {get,post,del,put} from "../http/http";
import {imgUrl, policyUrl, qxtUrl} from "../http/baseUrl";


// 列表
export const getProjectList = (params) => {
  return get(`${policyUrl}/project/projectInfo`,params)
}

// 基础信息
export const saveBase = (data) => {
  return post(`${policyUrl}/project/saveInfo`,data)
}

// 第二部保存
export const saveMain = (data) => {
  return post(`${policyUrl}/project/saveMain`,data)
}

// 保存申报项目附加信息
export const saveOther = (data) => {
  return post(`${policyUrl}/project/saveOther`,data)
}


// 继续申报
export const continueShen = (params) => {
  return get(`${policyUrl}/project/getProjectInfo`,params)
}


export const uploadImg = (data) => {
  return post(`${qxtUrl}/upload/base64Img`,data)
}


// 项目申报书

export const getQyxmk = (params) => {
  return get(`${policyUrl}/project/queryQyxmk`,params)
}


// 删除
export const delQyxmk = (data) => {
  return post(`${policyUrl}/project/deleteQyxmk`,data)
}

//
// /project/declareExport 申报项目信息导出
// /project/resultExport 评审得分情况导出

export const downloadInfo = (data) => {
  return get(`${policyUrl}/project/declareExport`,data)
}


export const downloadResult = (data) => {
  return get(`${policyUrl}/project/resultExport`,data)
}
